.layout {
  background-color: #fff;

  &__content {
    padding: 15px;
    background: #fff;

    &-residences {
      padding: 20px 15px 15px;
    }
  }
}
